<template>
  <div class="main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="核酸检测" name="1"></el-tab-pane>
      <el-tab-pane label="体温测量" name="2"></el-tab-pane>
      <el-tab-pane label="抗体检测" name="3"></el-tab-pane>
    </el-tabs>

    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.searchValue"
          placeholder="居民姓名/手机号/证件号" />

        <el-select
          class="select-width"
          size="medium"
          v-model="queryForm.country"
          clearable
          filterable
          placeholder="国籍">
          <el-option
            v-for="(item,v) in nationalitySelect"
            :key="v"
            :label="item.label"
            :value="item.label" />
        </el-select>

        <el-select
          class="select-width"
          size="medium"
          clearable
          v-model="queryForm.crowdTypeId"
          placeholder="人群分类">
          <el-option
            v-for="item in typepopSelect"
            :key="item.id"
            :label="item.ruleName"
            :value="item.id" />
        </el-select>

        <el-select
          class="select-width"
          size="medium"
          v-model="queryForm.status"
          placeholder="状态"
          v-if="activeName == 1">
          <el-option v-for="(item,v) in checkStatus" :key="v" :label="item.label" :value="item.value" />
        </el-select>

        <el-select
          v-btn-permission="'008001004001'"
          class="select-width"
          size="medium"
          clearable
          v-model="queryForm.isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker
          v-model="pickerTime"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          unlink-panels
          end-placeholder="结束日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="cogradient" v-btn-permission="'008001004002'">同步检测</el-button>
        <el-button type="primary" size="small" @click="queryCheckRecordInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo(null)">重置</el-button>
        <el-button type="primary" size="small" @click="exportCheckRecordInfo" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
      </div>
    </div>

    <!--c 列表/体温测量 列表-->
    <TableModel
      v-if="activeName != 3"
      :is-show-select="true"
      :is-show-tabs="false"
      :loading="loading"
      :goBeyondHeight="20"
      :table-data="checkRecordList"
      table-size="medium">
      <el-table-column prop="userInfo" label="居民信息" width="230" />
      <el-table-column prop="cardTypeName" label="证件类型" />
      <el-table-column prop="cardNo" label="证件号" width="200" />
      <el-table-column prop="isolationPointName" label="隔离点" />
      <el-table-column prop="barCode" label="条码号" width="200" v-if="activeName == 1" />
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column prop="crowdTypeName" label="人群分类" width="180" />

      <el-table-column prop="collectTime" label="核酸采样时间" width="200" v-if="activeName == 1" />
      <el-table-column prop="collectTime" label="体温测量时间" width="200" v-else />

      <el-table-column prop="tempValue" label="体温 (°C )" v-if="activeName == 2" />
      <el-table-column prop="symptom" label="体征" v-if="activeName == 2" />

      <el-table-column prop="status" label="状态" v-if="activeName == 1">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status == 1">已采样</el-tag>
          <el-tag size="small" type="success" v-else-if="scope.row.status == 2">已报告</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="status" label="检测结果" v-if="activeName == 1">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.labResult === '阴性'">阴性</el-tag>
          <el-tag size="small" type="danger" v-else-if="scope.row.labResult === '阳性'">阳性</el-tag>
          <el-tag size="small" type="warning" v-else-if="scope.row.labResult === '可疑'">可疑</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <!--抗体检测列表-->
    <TableModel
      v-else
      :is-show-select="true"
      :is-show-tabs="false"
      :loading="loading"
      :table-data="checkRecordList"
      table-size="medium">
      <el-table-column prop="userInfo" label="居民信息" width="230" />
      <el-table-column prop="cardTypeName" label="证件类型" />
      <el-table-column prop="cardNo" label="证件号" width="200" />
      <el-table-column prop="isolationPointName" label="隔离点" />
      <el-table-column prop="taskId" label="条码号" width="200" />
      <el-table-column prop="country" label="国籍"></el-table-column>
      <el-table-column prop="crowdTypeName" label="人群分类" width="200" />
      <el-table-column prop="collectTime" label="核酸采样时间" width="200" />
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status == 1">已采样</el-tag>
          <el-tag size="small" type="success" v-else-if="scope.row.status == 2">已报告</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="检测结果">
        <template slot-scope="scope">
          <div>IgG：{{ scope.row.iggLabResult }}</div>
          <div>IgM：{{ scope.row.igmLabResult }}</div>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-size="pageSize"
        :total="total"
        :page-sizes="[10, 20, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  tempList,
  exportTempList,
  covidList,
  exportCovidList,
  findAntibodyPage,
  exportAntibodyList,
  pullCovidResult
} from '../../api/QuarantineRegistration/quarantineRegistrationpop';
import constants from '../../common/constants';
import TableModel from '../../components/TableModel';
import { messageBox, importFile } from '../../utils/utils';
import { mapState } from 'vuex';

export default {
  name: "checkRecord",
  components: { TableModel },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      fullscreenLoading: false,
      checkRecordList: [],
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        status: "",
        isolationPointId: ""
      },
      activeName: "1",
      pickerTime: [],
      checkStatus: constants.CHECK_STATUS, // 状态
    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList,  // 隔离点列表数据
      typepopSelect: state => state.user.typepopSelect,  // 人群分类
      nationalitySelect: state => state.user.nationalitySelect,  // 国籍
    }),
  },

  created() {
    this.getCheckRecordList();
  },

  methods: {
    handleClick(tab) {
      this.activeName = tab.name;
      this.pageNumber = 1;
      this.resetQueryInfo("tabs");
      this.getCheckRecordList();
    },

    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let { searchValue, country, crowdTypeId, status, isolationPointId } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (country) params.country = country;
      if (crowdTypeId) params.crowdTypeId = crowdTypeId;
      if (status) params.status = status;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      if (this.pickerTime && this.pickerTime.length == 2) {
        params.startTime = this.pickerTime[0] + " 00:00:00";
        params.endTime = this.pickerTime[1] + " 23:59:59";
      }

      return params;
    },

    async getCheckRecordList() {
      this.loading = true;
      let params = this.setParamsInfo();
      console.log(params);
      let result;
      if (this.activeName == 1) {
        result = await covidList({ params });
      } else if (this.activeName == 2) {
        delete params.status;
        result = await tempList({ params });
      } else if (this.activeName == 3) {
        result = await findAntibodyPage({params})
      }
      this.renderListData(result);
    },

    // 渲染数据
    renderListData(result) {
      let { code, data, msg } = result.data;
      if (code == 200) {
        let { totalRow, list } = data;
        list.forEach(item => {
          constants.TYPE_CERTIFICATE.forEach(key => {
            if (item.cardType == key.value) item.cardTypeName = key.label
          })
          constants.SEX_LIST.forEach(key => {
            if (item.sex == key.value) {
              item.userInfo = `${item.name} ${key.label} ${item.age}岁\n${item.linkPhone}`
            }
          })
        })
        this.checkRecordList = list;
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    // 同步检测
    cogradient() {
      pullCovidResult().then(res => {
        this.$message.success('数据已同步');
        this.queryCheckRecordInfo()
      })
    },

    // 条件查询
    queryCheckRecordInfo() {
      this.pageNumber = 1;
      this.getCheckRecordList()
    },

    // 重置条件
    resetQueryInfo(val) {
      this.pickerTime = [];
      this.queryForm = {
        searchValue: "",
        country: "",
        crowdTypeId: "",
        status: "",
        isolationPointId: ""
      };
      if (!val) this.getCheckRecordList();
    },

    // 导出表格数据
    exportCheckRecordInfo() {
      let params = this.setParamsInfo();

      if (this.activeName == 1) {
        messageBox(() => {
          this.fullscreenLoading = true;
          exportCovidList(params).then(res => {
            this.fullscreenLoading = false;
            importFile(res.data, "核酸检测数据");
          })
        }, "warning", "是否确认导出核酸检测数据？")
      } else if (this.activeName == 2) {
        delete params.status;
        messageBox(() => {
          this.fullscreenLoading = true;
          exportTempList(params).then(res => {
            this.fullscreenLoading = false;
            importFile(res.data, "体温测量数据");
          })
        }, "warning", "是否确认导出体温测量数据？")
      } else if (this.activeName == 3) {
        delete params.status;
        messageBox(() => {
          this.fullscreenLoading = true;
          exportAntibodyList(params).then(res => {
            this.fullscreenLoading = false;
            importFile(res.data, "抗体检测数据");
          })
        }, "warning", "是否确认导出抗体检测数据？")
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getCheckRecordList();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getCheckRecordList();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";
</style>

<style scoped>
.el-dialog__wrapper >>> .el-dialog__body .el-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-table >>> td {
  padding: 6px 0 !important;
}

.el-table >>> .cell {
  line-height: 18px;
}
</style>
